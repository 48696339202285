import React, { useState } from 'react'
import Login from '../components/templates/Login'
import Secure3DS from '../components/templates/Secure3ds'
import Checkout from '@cmp/templates/Checkout'
import { useMutation } from '@apollo/client'
import { useCartState, useCartActions } from '../context/Cart'
import { CREATE_ORDER } from '../data/backend/queries'
import { useAuthentication } from '../context/Authentication'
import LoadingPage from '@cmp/templates/LoadingPage'
const API_ROOT_URL = process.env.GATSBY_API_ROOT_URL

const handleApplePay = (form, callback) => {
  const paymentRequest = {
    countryCode: 'IS',
    currencyCode: 'ISK',
    supportedNetworks: ['visa', 'masterCard'],
    merchantIdentifier: 'merchant.lyfogheilsa.teya',
    merchantCapabilities: ['supports3DS'],
    version: 14,
    total: { label: 'Lyf og Heilsa', amount: form.total }
  };
  const apForm = {...form}
  const session = new window.ApplePaySession(3, paymentRequest)
  session.onvalidatemerchant = event => {
    // This is called as soon as the session starts and Apple Pay launches
    if(event.type !== 'validatemerchant') return
    const validationURL = event.validationURL;
    fetch(`${API_ROOT_URL}/api/apple-pay/merchant/validate`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ validationURL, origin: 'loh' }),
    })
    .then(response => response.json())
    .then(response => {
      session.completeMerchantValidation(response);
      apForm.merchantId = response.merchantIdentifier
    })
    .catch(error => {
      // Apple Pay popup shows the user an error message
      console.error(error);
    })
  };
  session.onpaymentauthorized = event => {
    apForm.paymentObj = event.payment
    delete apForm.total
    callback({
      variables: {
        input: apForm
      }
    })
  }
  session.begin()
};

export default () => {
  const [anonymousCheckout, setAnonymousCheckout] = useState(false)
  const [threeDsHtml, setThreeDsHtml] = useState("")
  const { user, loading: userLoading } = useAuthentication()
  const { cart, loading: cartLoading } = useCartState()
  const { changeQuantity } = useCartActions()

  const [createOrder, { data, error, loading: submitting }] = useMutation(
    CREATE_ORDER,
    {
      onCompleted: ({ createOrder: r }) => {
        if (r.__typename === 'ThreeDsResponse') {
          if(r.html) {
            setThreeDsHtml(r.html)
          } else if(r.redirect) {
            window.location.href = r.redirect
          }
        }
      }
    }
  )

  if (userLoading || cartLoading) {
    return <LoadingPage />
  }

  if (!user && !anonymousCheckout) {
    return <Login onSkipLogin={() => setAnonymousCheckout(true)} />
  }

  const onSubmit = form => {
    if (!submitting) {
      const shippingVariant = form.shippingVariant ? form.shippingVariant[form.shippingType] : undefined
      const formObj = {
        ...form,
        shippingVariant,
        token: cart.token,
        backToOnSuccess: location.origin + '/takk-fyrir?clear',
        backToOnCancel: location.origin,
        origin: 'loh'
      }
      if(form.paymentMethod === 'apple_pay') {
        handleApplePay(formObj, createOrder)
      } else {
        delete formObj.total
        createOrder({
          variables: {
            input: formObj
          }
        })
      }
      // send a purchase event to Google Analytics
      window.dataLayer = window.dataLayer || []
      dataLayer.push({ ecommerce: null })
      dataLayer.push({
        event: 'purchase', 
        ecommerce: {
          transaction_id: cart.token,
          value: cart.totalPrice,
          coupon: cart.promoCode || '',
          currency: 'ISK',
          shipping: shippingVariant ? shippingVariant.price : 0,
          tax: 0,
          items: cart.products.map((item, key) => ({
            item_id: item.id,
            item_name: item.product.title,
            discount: item.totalDiscount,
            index: key,
            item_category: item.productGroup.category.title,
            quantity: item.quantity,
            price: item.totalPrice
          }))
      }})
    }
  }

  const response = data && data.createOrder
  return (
    <>
    { !threeDsHtml ? <Checkout
      cart={cart}
      onSubmit={onSubmit}
      submitting={submitting || response?.__typename === 'ThreeDsResponse'}
      error={error || (response?.__typename === 'ErrorMessage' && response)}
      onChangeQuantity={changeQuantity}
    /> : <Secure3DS html={threeDsHtml} /> 
    }
    </>
  )
}
