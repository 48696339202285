import React, { useRef, useEffect } from 'react'

const Secure3DS = ({ html }) => {
  const formContainerRef = useRef(null);
  useEffect(() => {
    // Submit the form programmatically after it has been injected into the DOM
    const form = formContainerRef.current?.querySelector('#webform0');
    if (form) {
      form.submit();
    }
  }, []); 

  return (
    <div
      ref={formContainerRef}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  )
}

export default Secure3DS