import React from 'react'
import { Link } from '@kogk/gatsby-theme-base'
import { Row, Col, Container } from '@kogk/common'
import cn from 'classnames'
import { useQuery } from '@apollo/client'
import { AddressForm } from '@cmp/templates/ViewTemplate/Types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import RadioButton from '@cmp/site/RadioButton'
import { formatPriceString } from '@src/utils'
import {
  GET_VIEWER_DETAILS,
  GET_POST_CODES,
  GET_SHIPPING_OPTIONS
} from '@src/data/backend/queries'
import { useForm, Controller } from 'react-hook-form'
import { ProductRow, Button, ValidationMessage, NativeSelect } from '@cmp/site'
import styles from './CheckoutForm.module.scss'
import { useCartState } from '@src/context/Cart'
import { CartSummary } from '@src/components/site'
import CreditCardForm from '../../templates/ViewTemplate/Types/CreditCardForm'

export default ({ cart, onChangeQuantity, onSubmit, submitting, error }) => {
  const { t } = useLanguage()
  const { cartSize, cartToken } = useCartState()
  const heading = !!cartSize ? 'Karfan mín' : 'Karfan er tóm'

  const {
    register,
    handleSubmit,
    watch,
    control,
    errors,
    setValue,
    value
  } = useForm({
    mode: 'onTouched',
    defaultValues: {
      paymentMethod: 'credit_card'
    }
  })
  const postCode = watch('postCode')
  const shippingType = watch('shippingType')
  const shippingVariant = watch('shippingVariant')
  const paymentMethod = watch('paymentMethod')

  const paymentOptions = ['credit_card']
  // TODO: Verify apple pay functionality
  // if (
  //   typeof window !== 'undefined' &&
  //   window.ApplePaySession &&
  //   window.ApplePaySession.canMakePayments()
  // ) {
  //   paymentOptions.push('apple_pay')
  // }

  const { data: { postCodes = [] } = {} } = useQuery(GET_POST_CODES)
  const shippingQuery = useQuery(GET_SHIPPING_OPTIONS, {
    skip: !postCode,
    variables: {
      input: {
        cartToken,
        postCode: Number(postCode)
      }
    }
  })

  const { shippingOptions = [] } =
    shippingQuery.data ?? shippingQuery.previousData ?? {}

  useQuery(GET_VIEWER_DETAILS, {
    skip: cartSize === 0,
    onCompleted: ({ viewer }) => {
      if (viewer) {
        setValue('name', viewer.details.name)
        setValue('street', viewer.details.address)
        setValue('postCode', viewer.details.postCode)
        setValue('phone', viewer.phone)
      }
    }
  })

  const variantValidation = variant => {
    const selectedVariant = shippingVariant[shippingType]
    const variants =
      shippingOptions.find(o => o.type === shippingType)?.variants ?? null
    return !!variants ? !!variants.find(v => v.id === selectedVariant) : true
  }

  const shippingCost =
    shippingOptions.find(o => o.type === shippingType)?.price ?? 0
  const totalPrice = cart.totalPrice + shippingCost

  return (
    <Container>
      <Row className='justify-content-center positon-relative'>
        <Col col={{ md: 5 }} className='pb-3 px-md-0'>
          <div className={styles.header}>
            <h1 className='hdln--3'>{heading}</h1>
            <Link to='/karfa' className='d-inline d-md-none'>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
          {cartSize > 0 && (
            <>
              <div
                className={cn(styles.cartContainer, {
                  [styles.bottomShade]: cartSize > 2
                })}
              >
                <div className={styles.cartItemsDesktop}>
                  {cart.products.map(item => {
                    const productVariant =
                      item.product.title !== item.productGroup.title
                        ? item.product.title
                        : null
                    return (
                      <ProductRow
                        key={item.id}
                        image={
                          item.productGroup.images[0] &&
                          (item.productGroup.images[0].largeUrl ??
                            item.productGroup.images[0].thumbnailUrl)
                        }
                        description={item.product.title}
                        quantity={item.quantity}
                        maxQuantity={item.product.maxQuantity}
                        onChangeQuantity={qty => onChangeQuantity(item.id, qty)}
                        price={item.totalPrice}
                        variants={[productVariant]}
                        checkout
                        id={item.id}
                        productType='product'
                        productLink={`/${item.productGroup.category.slug}/${item.productGroup.id}`}
                        discount={item.discountPercent}
                        fullPrice={item.totalDiscount + item.totalPrice}
                      />
                    )
                  })}
                  {cart.prescriptions.map(item => (
                    <ProductRow
                      key={item.id}
                      description={item.prescription.description}
                      quantity={item.quantity}
                      maxQuantity={item.prescription.maxQuantity}
                      onChangeQuantity={qty => onChangeQuantity(item.id, qty)}
                      price={item.totalPrice}
                      variants={[]}
                      checkout
                      id={item.id}
                      productType='prescription'
                    />
                  ))}
                </div>
              </div>
              <div className='d-flex mt-3 pt-1 justify-content-between'>
                <p className='parag--medium-bold blue'>Upplýsingar</p>
              </div>
              <AddressForm
                className='d-flex flex-column justify-content-between'
                postCodes={postCodes}
                control={control}
                register={register}
                errors={errors}
                setValue={setValue}
              />
              <fieldset>
                <input
                  ref={register()}
                  name='total'
                  type='hidden'
                  value={totalPrice}
                />
              </fieldset>
              <div className='d-flex mt-3 pt-1 justify-content-between'>
                <p className='parag--medium-bold blue'>Afhending</p>
              </div>
              <Controller
                name='shippingType'
                control={control}
                rules={{
                  validate: v =>
                    v == null ? 'Veldu afhendingarmáta' : undefined
                }}
                render={({ value, onChange }) => {
                  if (!postCode || shippingOptions.length === 0) {
                    return (
                      <div className='bordered-wrapper text-center parag parag--small-fixed-size family--display'>
                        {t('checkout|info-required')}
                      </div>
                    )
                  }

                  return shippingOptions.map(option => (
                    <Button
                      key={option.type}
                      type='blanco'
                      onClick={() => onChange(option.type)}
                      secondary
                      className={cn(
                        'bordered-wrapper flex-column align-items-start',
                        {
                          [styles.selected]: value === option.type,
                          [styles.hasError]: errors.shippingType
                        }
                      )}
                    >
                      <div className='w-100 d-flex align-items-center justify-content-between'>
                        <RadioButton
                          label={option.name}
                          className={styles.radioLabel}
                          checked={value === option.type}
                        />
                        <p
                          className={cn(
                            styles.priceLabel,
                            'parag parag--small-bold mb-1 opacity--08'
                          )}
                        >
                          {option.price
                            ? formatPriceString(option.price, true)
                            : 'Frítt'}
                        </p>
                      </div>
                      {option.description && (
                        <p
                          className={cn(
                            styles.smallerFont,
                            'parag--feature-off black opacity--08'
                          )}
                        >
                          {option.description}
                        </p>
                      )}
                      {option.variants && (
                        <NativeSelect
                          ref={register({
                            validate: v => {
                              if (variantValidation(v) == true) {
                                return undefined
                              } else {
                                if (
                                  option.type === 'POSTOFFICE' ||
                                  option.type === 'FREE_POSTOFFICE'
                                ) {
                                  return 'Pósthús má ekki vera autt'
                                } else if (
                                  option.type === 'POSTBOX' ||
                                  option.type === 'FREE_POSTBOX'
                                ) {
                                  return 'Póstbox má ekki vera autt'
                                }
                              }
                            }
                          })}
                          name={`shippingVariant.${option.type}`}
                          options={[
                            { label: '', value: '' },
                            ...option.variants.map(v => ({
                              value: v.id,
                              label: v.name
                            }))
                          ]}
                        />
                      )}
                    </Button>
                  ))
                }}
              />
              {errors.shippingType && (
                <ValidationMessage message={errors.shippingType.message} />
              )}
              {errors.shippingVariant &&
                errors.shippingVariant.POSTBOX &&
                shippingType === 'POSTBOX' && (
                  <ValidationMessage
                    message={errors.shippingVariant.POSTBOX.message}
                  />
                )}
              {errors.shippingVariant &&
                errors.shippingVariant.FREE_POSTBOX &&
                shippingType === 'FREE_POSTBOX' && (
                  <ValidationMessage
                    message={errors.shippingVariant.FREE_POSTBOX.message}
                  />
                )}
              {errors.shippingVariant &&
                errors.shippingVariant.POSTOFFICE &&
                shippingType === 'POSTOFFICE' && (
                  <ValidationMessage
                    message={errors.shippingVariant.POSTOFFICE.message}
                  />
                )}
              {errors.shippingVariant &&
                errors.shippingVariant.FREE_POSTOFFICE &&
                shippingType === 'FREE_POSTOFFICE' && (
                  <ValidationMessage
                    message={errors.shippingVariant.FREE_POSTOFFICE.message}
                  />
                )}
              <Col
                col={{ lg: 4 }}
                className={cn('d-block d-md-none', styles.rightContainer)}
              >
                <CartSummary price={totalPrice} shippingCost={shippingCost} />
              </Col>
              <div className='d-flex mt-3 pt-1 justify-content-between'>
                <p className='parag--medium-bold blue'>Greiðsluupplýsingar</p>
              </div>
              <Controller
                name='paymentMethod'
                control={control}
                render={({ value, onChange }) => (
                  <>
                    {paymentOptions.includes('apple_pay') && (
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <button
                          onClick={() => onChange('apple_pay')}
                          className={cn(
                            styles.applePayButton,
                            styles.applePayButtonBlack
                          )}
                        />
                        {error && (
                          <div className='pt-2'>
                            <ValidationMessage message={error.message} />
                          </div>
                        )}
                      </form>
                    )}
                  </>
                )}
              />
              {paymentOptions.includes('apple_pay') && (
                <div className='position-relative d-flex justify-content-between align-items-center my-1'>
                  <span className={styles.paymentMethodTextLine} />
                  <span className={styles.paymentMethodText}>
                    Eða greiddu með korti
                  </span>
                  <span className={styles.paymentMethodTextLine} />
                </div>
              )}
              <CreditCardForm
                className='d-flex flex-column justify-content-between'
                control={control}
                register={register}
                errors={errors}
                setValue={setValue}
                skipValidation={paymentMethod !== 'credit_card'}
              />
              <div
                className={cn(
                  'd-none d-md-block w-100 opacity--08',
                  styles.logo
                )}
              />
              <Controller
                name='paymentMethod'
                control={control}
                render={({ value, onChange }) => (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Button
                      onClick={() => onChange('credit_card')}
                      label={submitting ? '' : 'Klára kaup'}
                      icon={submitting ? 'loading' : ''}
                      primary
                      large
                      rounded
                      bold
                      className={cn('w-100', styles.buttonLabels)}
                    />
                    {error && (
                      <div className='pt-2'>
                        <ValidationMessage message={error.message} />
                      </div>
                    )}
                  </form>
                )}
              />
            </>
          )}
        </Col>
        <Col
          col={{ lg: 4 }}
          className={cn('d-none d-md-block', styles.rightContainer)}
        >
          <CartSummary price={totalPrice} shippingCost={shippingCost} />
        </Col>
      </Row>
    </Container>
  )
}
