import React, { useContext, useEffect } from 'react'
import { GlobalDataProvider } from '@src/data/global-data'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import { MenuTypeContext } from '@root/src/context/MenuTypeContext'
import { MainLayout } from '@cmp/site'
import CheckoutForm from '@cmp/site/CheckoutForm/CheckoutForm'

export default ({
  category = 'Ganga frá kaupum',
  ...props
}) => {
  const { language } = useLanguage()
  const { modify } = useContext(MenuTypeContext)

  useEffect(() => {
    modify('primary', category)
  }, [category])

  return (
    <GlobalDataProvider language={language} altUrls={[]}>
      <MainLayout>
        <CheckoutForm {...props} />
      </MainLayout>
    </GlobalDataProvider>
  )
}
